import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { NavigationMenuLink } from "@radix-ui/react-navigation-menu";
import { SettingsTab } from "./SettingsManagerUI";
import { useIsDesktop } from "../hooks/useIsDesktop";

export interface SettingsMenuProps {
  activeMode: SettingsTab;
  setActiveMode: (mode: SettingsTab) => void;
}
export function SettingsMenu({
  activeMode,
  setActiveMode,
}: Readonly<SettingsMenuProps>) {
  const isDesktop = useIsDesktop();
  return (
    <NavigationMenu
      value={activeMode}
      onValueChange={(value) => {
        setActiveMode(value as SettingsTab);
      }}
    >
      <NavigationMenuList>
        <NavigationMenuItem value="aihelp">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "knowledge" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("knowledge");
            }}
          >
            Knowledge
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem value="script">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "script" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("script");
            }}
          >
            Call scripts
          </NavigationMenuLink>
        </NavigationMenuItem>
        {!isDesktop && (
          <NavigationMenuItem value="download">
            <NavigationMenuLink
              className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "download" ? "font-bold border border-gray-300" : ""}`}
              onClick={() => {
                setActiveMode("download");
              }}
            >
              Download app
            </NavigationMenuLink>
          </NavigationMenuItem>
        )}
      </NavigationMenuList>
    </NavigationMenu>
  );
}
