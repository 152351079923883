import { useCallDisplay } from "./useCallDisplay";
import { CallDisplayUI } from "./CallDisplayUI";
import type PartySocket from "partysocket";

export function CallDisplay({
  mainSocket,
  host,
  roomId,
  leaveCall,
}: Readonly<{
  mainSocket: PartySocket;
  roomId: string;
  leaveCall: () => void;
  host: string;
}>) {
  const callDisplayProps = useCallDisplay(roomId, host);
  const { reconnectAttempt, ...callDisplayUIProps } = callDisplayProps;
  return (
    <CallDisplayUI
      key={reconnectAttempt}
      {...callDisplayUIProps}
      leaveCall={leaveCall}
      host={host}
      roomId={roomId}
      mainSocket={mainSocket}
    />
  );
}
