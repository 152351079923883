import { Button } from "@/components/ui/button";
import {
  CompressedTable,
  CompressedTableBody,
  CompressedTableCell,
  CompressedTableRow,
} from "@/components/ui/table-compressed";
import { Sparkles } from "lucide-react";
import type { TranscriptUpdate } from "party/types";
import { SmoothScrollArea } from "@/components/ui/smooth-scroll-area";
import { useEffect, useRef } from "react";
import { TranscriptSentence } from "../utils/transcriptProcessor";
import { useGroupedTranscript } from "../hooks/useGroupedTranscript";
import { HandleGetHelpFunction } from "./AiHelpCard";

export interface TranscriptTableProps {
  transcript: TranscriptUpdate[];
  handleGetHelp: HandleGetHelpFunction;
}

export interface Speaker {
  initials: string;
  name: string;
  id: number;
  color: string;
}

export function TranscriptTable({
  transcript,
  handleGetHelp,
}: Readonly<TranscriptTableProps>) {
  const scrollAreaRef = useRef<
    HTMLDivElement & { smoothScrollTo: (options?: ScrollToOptions) => void }
  >(null);
  // Effect for initial scroll on mount
  useEffect(() => {
    scrollToBottom();
  }, [scrollAreaRef.current]);

  // Effect for scrolling when transcript updates
  useEffect(() => {
    scrollToBottom();
  }, [transcript]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollAreaRef.current) {
        if (scrollAreaRef.current.smoothScrollTo) {
          scrollAreaRef.current.smoothScrollTo({
            top: scrollAreaRef.current.scrollHeight,
          });
        }
      }
    }, 100);
  };

  const getSpeakerInitials = (name: string) =>
    name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();

  const speakers = transcript.reduce(
    (acc, item) => {
      if (!acc[item.speaker_id]) {
        const initials = getSpeakerInitials(item.speaker);
        const count = Object.values(acc).filter((s) =>
          s.initials.startsWith(initials)
        ).length;
        acc[item.speaker_id] = {
          initials: count > 0 ? `${initials}${count + 1}` : initials,
          name: item.speaker,
          id: item.speaker_id,
          color: `hsl(${(item.speaker_id * 137) % 360}, 70%, 40%)`,
        };
      }
      return acc;
    },
    {} as Record<number, Speaker>
  );

  console.log("Transcript speakers", speakers);

  const groupedTranscript = useGroupedTranscript(transcript);

  return (
    <>
      <SmoothScrollArea
        ref={scrollAreaRef}
        className="h-full flex-grow"
        smoothScroll
      >
        <CompressedTable>
          <CompressedTableBody>
            {groupedTranscript.map((sentence) => (
              <TranscriptTableItem
                speakers={speakers}
                key={sentence.id}
                sentence={sentence}
                handleGetHelp={handleGetHelp}
              />
            ))}
          </CompressedTableBody>
        </CompressedTable>
      </SmoothScrollArea>
    </>
  );
}

export interface TranscriptTableItemProps {
  sentence: TranscriptSentence;
  handleGetHelp: HandleGetHelpFunction;
  speakers: Record<number, Speaker>;
}

export function TranscriptTableItem({
  sentence,
  handleGetHelp,
  speakers,
}: Readonly<TranscriptTableItemProps>) {
  const speaker = speakers[sentence.speaker_id];

  return (
    <CompressedTableRow key={sentence.id} className="align-top">
      <CompressedTableCell className="align-top">
        <div className="p-1">
          <div
            className={`inline-block mt-[0.25em] px-1 py-0.5 rounded-full text-white text-center text-xs`}
            style={{ backgroundColor: speaker.color }}
          >
            <span className="inline-block min-w-[3ch]">{speaker.initials}</span>
          </div>
        </div>
      </CompressedTableCell>
      <CompressedTableCell>{sentence.sentence}</CompressedTableCell>
      <CompressedTableCell className="text-right align-top">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => {
            handleGetHelp({
              question: sentence.sentence,
              itemIds: sentence.item_ids,
              speakerId: sentence.speaker_id,
            });
          }}
          aria-label="Get help"
        >
          <Sparkles size={16} />
        </Button>
      </CompressedTableCell>
    </CompressedTableRow>
  );
}
