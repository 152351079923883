import { useCallManager } from "./useCallManager";
import { CallManagerUI } from "./CallManagerUI";
import { CallManagerWrapper } from "./CallManagerWrapper";
import PartySocket from "partysocket";
import { useIsDesktop } from "../hooks/useIsDesktop";
import { useState } from "react";
import { DownloadAppOrContinueOnWeb } from "./DownloadAppOrContinueOnWeb";

export function CallManager({
  host,
  partySocket,
  authServiceUrl,
}: {
  host: string;
  partySocket: PartySocket;
  authServiceUrl: string | null;
}) {
  const [hasChosenToContinueOnWeb, setHasChosenToContinueOnWeb] =
    useState(false);
  const callManagerProps = useCallManager(host);
  const isDesktop = useIsDesktop();

  const shouldAskToContinueOnWeb = !isDesktop && !hasChosenToContinueOnWeb;

  return (
    <CallManagerWrapper>
      {shouldAskToContinueOnWeb ? (
        <DownloadAppOrContinueOnWeb
          continueOnWeb={() => setHasChosenToContinueOnWeb(true)}
        />
      ) : (
        <CallManagerUI
          {...callManagerProps}
          partySocket={partySocket}
          authServiceUrl={authServiceUrl}
          mainSocket={callManagerProps.mainSocket}
        />
      )}
    </CallManagerWrapper>
  );
}
