import { Button } from "@/components/ui/button";
import {
  CompressedTable,
  CompressedTableBody,
  CompressedTableCell,
  CompressedTableRow,
} from "@/components/ui/table-compressed";
import { Sparkles, User } from "lucide-react";
import type { MeetingParticipant, TranscriptUpdate } from "party/types";
import { SmoothScrollArea } from "@/components/ui/smooth-scroll-area";
import { useCallback, useEffect, useRef, useState } from "react";
import { TranscriptSentence } from "../utils/transcriptProcessor";
import { useGroupedTranscript } from "../hooks/useGroupedTranscript";
import { HandleGetHelpFunction } from "./AiHelpCard";
import {
  GuestHostFilterDropdown,
  type GuestHostFilter,
} from "./GuestHostFilterDropdown";
import useLocalStorageState from "use-local-storage-state";
import { Card } from "@/components/ui/card";

export interface TranscriptTableProps {
  participants: MeetingParticipant[];
  transcript: TranscriptUpdate[];
  handleGetHelp: HandleGetHelpFunction;
}

export interface Speaker {
  initials: string;
  name: string;
  id: number;
  color: string;
}

export function TranscriptTable({
  participants,
  transcript,
  handleGetHelp,
}: Readonly<TranscriptTableProps>) {
  const [autoScrollPaused, setAutoScrollPaused] = useState(false);
  const autoScrollTimer = useRef<NodeJS.Timeout | null>(null);
  const scrollAreaRef = useRef<
    HTMLDivElement & { smoothScrollTo: (options?: ScrollToOptions) => void }
  >(null);
  useEffect(() => {
    scrollToBottom();
  }, [scrollAreaRef.current]);

  useEffect(() => {
    if (!autoScrollPaused) {
      scrollToBottom();
    }
  }, [transcript, autoScrollPaused]);

  const handleWheel = () => {
    setAutoScrollPaused(true);
    if (autoScrollTimer.current) {
      clearTimeout(autoScrollTimer.current);
    }
    autoScrollTimer.current = setTimeout(() => {
      setAutoScrollPaused(false);
    }, 30000);
  };

  const handleGetSentenceHelp = useCallback((sentence: TranscriptSentence) => {
    console.log("Getting help for sentence:", sentence, transcript);
    handleGetHelp({
      transcriptItemIds: sentence.item_ids,
    });
  }, []);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollAreaRef.current) {
        if (scrollAreaRef.current.smoothScrollTo) {
          scrollAreaRef.current.smoothScrollTo({
            top: scrollAreaRef.current.scrollHeight,
          });
        }
      }
    }, 100);
  };

  const getSpeakerInitials = (name: string) =>
    name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();

  const speakers = transcript.reduce(
    (acc, item) => {
      if (!acc[item.speaker_id]) {
        const initials = getSpeakerInitials(item.speaker);
        const count = Object.values(acc).filter((s) =>
          s.initials.startsWith(initials)
        ).length;
        acc[item.speaker_id] = {
          initials: count > 0 ? `${initials}${count + 1}` : initials,
          name: item.speaker,
          id: item.speaker_id,
          color: `hsl(${(item.speaker_id * 137) % 360}, 70%, 40%)`,
        };
      }
      return acc;
    },
    {} as Record<number, Speaker>
  );

  const [displayFilter, setDisplayFilter] =
    useLocalStorageState<GuestHostFilter>("transcript-display-filter", {
      defaultValue: "guest",
    });

  const filteredTranscript = transcript.filter((item) => {
    const participant = participants.find((p) => p.id === item.speaker_id);
    if (displayFilter === "all") {
      return true;
    } else if (displayFilter === "host") {
      return participant?.is_host === true;
    } else if (displayFilter === "guest") {
      return participant?.is_host === false;
    } else if (displayFilter === "unknown") {
      return !participant;
    }
    return false;
  });

  const groupedTranscript = useGroupedTranscript(filteredTranscript);

  return (
    <>
      <div className="">
        <div className="flex flex-row w-full">
          <div className="flex-grow">
            <h2 className="font-semibold flex-grow pt-1 pl-2">Transcript</h2>
          </div>
          <div className="flex-grow-0">
            <div className="flex flex-row space-x-4">
              <div className="flex flex-row space-x-1 p-1">
                <div className="text-sm">{participants.length}</div>
                <div className="p-0.5">
                  <User size={16} />
                </div>
              </div>
              <div>
                <GuestHostFilterDropdown
                  value={displayFilter}
                  onSelect={setDisplayFilter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card className="h-full w-full flex flex-col">
        <SmoothScrollArea
          ref={scrollAreaRef}
          className="h-full flex-grow"
          smoothScroll
          onWheel={handleWheel}
        >
          <CompressedTable>
            <CompressedTableBody>
              {groupedTranscript.map((sentence) => (
                <TranscriptTableItem
                  speakers={speakers}
                  key={sentence.id}
                  sentence={sentence}
                  handleGetSentenceHelp={handleGetSentenceHelp}
                />
              ))}
            </CompressedTableBody>
          </CompressedTable>
        </SmoothScrollArea>
      </Card>
    </>
  );
}

export interface TranscriptTableItemProps {
  sentence: TranscriptSentence;
  handleGetSentenceHelp: (sentence: TranscriptSentence) => void;
  speakers: Record<number, Speaker>;
}

export function TranscriptTableItem({
  sentence,
  handleGetSentenceHelp,
  speakers,
}: Readonly<TranscriptTableItemProps>) {
  const speaker = speakers[sentence.speaker_id];

  return (
    <CompressedTableRow key={sentence.id} className="align-top">
      <CompressedTableCell className="align-top w-6">
        <div className="p-1">
          <div
            className={`inline-block mt-[0.25em] px-1 py-0.5 rounded-full text-white text-center text-xs`}
            style={{ backgroundColor: speaker.color }}
          >
            <span className="inline-block min-w-[3ch]">{speaker.initials}</span>
          </div>
        </div>
      </CompressedTableCell>
      <CompressedTableCell>{sentence.sentence}</CompressedTableCell>
      <CompressedTableCell className="text-right align-top">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => {
            handleGetSentenceHelp(sentence);
          }}
          aria-label="Get help"
        >
          <Sparkles size={16} />
        </Button>
      </CompressedTableCell>
    </CompressedTableRow>
  );
}
