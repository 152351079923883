import { Button } from "@/components/ui/button";

import { Input } from "@/components/ui/input";
import {
  OrganizationSwitcher,
  useOrganization,
  UserButton,
  useUser,
} from "@clerk/clerk-react";
import { Settings, Loader2, Pin, PinOff } from "lucide-react";

import { CallDisplay } from "./CallDisplay";
import type { Call, LogMessage } from "party/types";
import { useSessionStorage } from "usehooks-ts";
import { useEffect, useMemo, useState } from "react";
import { useAnalytics } from "../AnalyticsProvider";
import APP_VERSION from "../../../version.json";

import PartySocket from "partysocket";
import { SettingsManagerUI } from "./SettingsManagerUI";
import { CallList } from "./CallList/CallList";
import {
  CallScriptsResponse,
  CallScriptsResponseSchema,
} from "./Script/CallScriptManagerUI";
import { useQuery } from "@tanstack/react-query";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CallScript } from "./Script/types/CallScript";
import invariant from "tiny-invariant";
import { ConnectZoom } from "./ConnectZoom";
import { isDesktop, useIsDesktop } from "../hooks/useIsDesktop";
import { useNeutralino } from "./Neutralino/NeutralinoContextProvider";
import { ToggleAlwaysOnTop } from "./Neutralino/ToggleAlwaysOnTop";
import { CallScriptSelector } from "./CallScriptSelector";
export interface CallManagerUIProps {
  calls: Call[];
  host: string;
  selectedCall: string | null;
  error: string | null;
  logs: LogMessage[];
  createCall: (url: string, callScript: CallScript) => void;
  deleteCall: (roomId: string) => void;
  selectCall: (roomId: string) => void;
  leaveCall: () => void;
  clearLogs: () => void;
  partySocket: PartySocket;
  mainSocket: PartySocket;
  authServiceUrl: string | null;
}

export function CallManagerUI({
  authServiceUrl,
  host,
  calls,
  selectedCall,
  error,
  logs,
  createCall,
  deleteCall,
  selectCall,
  leaveCall,
  clearLogs,
  partySocket,
  mainSocket,
}: Readonly<CallManagerUIProps>) {
  const [url, setUrl] = useState("");
  const [isZoomUrl, setIsZoomUrl] = useState(false);
  const [hasZoomAccount, setHasZoomAccount] = useState(false);

  const { trackEvent } = useAnalytics();
  const { user } = useUser();
  const [selectedScript, setSelectedScript] = useState<CallScript | null>(null);
  const [currentCallSession, setCurrentCallSession] = useSessionStorage<
    string | null
  >("currentCallSession", null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);

  const validateUrl = useMemo(() => {
    return (url: string) => {
      try {
        new URL(url.trim());
        return true;
      } catch (_) {
        return false;
      }
    };
  }, []);

  useEffect(() => {
    setIsValidUrl(validateUrl(url));
  }, [url, validateUrl]);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const leaveSettings = () => {
    setShowSettings(false);
  };

  useEffect(() => {
    if (selectedCall && selectedCall !== currentCallSession) {
      // User has joined a new call
      trackEvent("user_joined_call", {
        roomId: selectedCall,
        previousRoomId: currentCallSession,
      });
      setCurrentCallSession(selectedCall);
    }
  }, [selectedCall, currentCallSession, setCurrentCallSession, trackEvent]);

  const handleCreateCall = async () => {
    invariant(selectedScript);
    if (isZoomUrl && !hasZoomAccount) {
      // Don't allow creating a Zoom call without a connected account
      return;
    }
    setIsLoading(true);
    if (!isValidUrl) {
      setIsLoading(false);
      return;
    }
    createCall(url.trim(), selectedScript);

    // Set a timeout to re-enable the button after 5 seconds
    setTimeout(() => {
      setUrl("");
      setSelectedScript(null);
      setIsLoading(false);
    }, 5000);
  };

  const [showConnectZoom, setShowConnectZoom] = useState(false);

  const handleConnectZoom = () => {
    setShowConnectZoom(false);
  };

  const checkZoomConnection = () => {
    const zoomUrl = url.toLowerCase().includes("zoom");
    setIsZoomUrl(zoomUrl);

    const zoomAccount = user?.externalAccounts?.find(
      (account) => account.provider === "custom_zoom"
    );
    setHasZoomAccount(!!zoomAccount);

    if (zoomUrl && !zoomAccount) {
      setShowConnectZoom(true);
    } else {
      setShowConnectZoom(false);
    }
  };

  useEffect(() => {
    checkZoomConnection();
  }, [url, user?.externalAccounts]);

  const sortedCalls = [...calls].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  return (
    <>
      {error && <div style={{ color: "red" }}>{error}</div>}
      {showConnectZoom && (
        <ConnectZoom
          onConnect={handleConnectZoom}
          authServiceUrl={authServiceUrl}
        />
      )}

      {selectedCall === null && !showSettings && (
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center w-full p-2">
            <div>
              <OrganizationSwitcher />
            </div>
            <div className="text-right flex flex-row space-x-2">
              <div>
                <ToggleAlwaysOnTop />
              </div>

              <div>
                <Button onClick={toggleSettings} variant="outline" size="icon">
                  <Settings className="h-4 w-4" />
                </Button>
              </div>
              <div className="p-1">
                <UserButton />
              </div>
            </div>
          </div>
          <div className="flex-grow overflow-hidden px-4 h-full flex flex-col space-y-4">
            <div className="">
              <h2 className="text-lg font-medium mb-1">
                Start an AI-assisted call
              </h2>

              <p className="text-sm text-gray-400 mb-3">
                Enter a Google Meet, Teams or Zoom URL. The AI assistant will
                join.
              </p>

              <div className="flex gap-4 w-full">
                <div className="flex-grow">
                  <Input
                    className="px-2 w-full"
                    type="text"
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                      checkZoomConnection();
                    }}
                    placeholder="https://meet.google.com/abc-xyz"
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <CallScriptSelector
                    mainSocket={mainSocket}
                    selectedScript={selectedScript}
                    onSelect={(callScript: CallScript) =>
                      setSelectedScript(callScript)
                    }
                  />
                </div>
                <Button
                  variant="default"
                  onClick={handleCreateCall}
                  disabled={
                    isLoading ||
                    !isValidUrl ||
                    !selectedScript ||
                    (isZoomUrl && !hasZoomAccount)
                  }
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Starting...
                    </>
                  ) : (
                    "Start"
                  )}
                </Button>
              </div>
              {isZoomUrl && !hasZoomAccount && (
                <p className="text-sm text-red-500 mt-2">
                  Please connect your Zoom account to start a Zoom call.
                </p>
              )}
            </div>

            <div className="flex-grow overflow-hidden flex flex-col space-y-3">
              <div>
                <h2 className="text-lg font-medium mb-1">Calls</h2>

                <p className="text-sm text-gray-400">
                  Calls started by anyone in your organisation are listed here.
                </p>
              </div>

              <div className="flex-grow overflow-hidden">
                <CallList
                  calls={sortedCalls}
                  selectCall={selectCall}
                  deleteCall={deleteCall}
                />
              </div>
            </div>
          </div>
          <div className="flex-0 flex-shrink-0 p-4">
            <p className="text-xs text-gray-400 uppercase">
              Version: {APP_VERSION.semanticVersion}
            </p>
          </div>
        </div>
      )}

      {user?.publicMetadata?.isDeveloper === true && selectedCall === null && (
        <>
          <h2>Server Logs</h2>
          <Button variant="default" onClick={clearLogs}>
            Clear Logs
          </Button>
          <div
            id="logs"
            style={{
              height: "200px",
              overflowY: "scroll",
              border: "1px solid #ccc",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            {logs.map((log, index) => (
              <div key={index}>
                <small>[{new Date(log.timestamp).toLocaleTimeString()}]</small>{" "}
                {log.message}
              </div>
            ))}
          </div>
        </>
      )}

      {selectedCall && !showSettings && (
        <CallDisplay
          mainSocket={mainSocket}
          roomId={selectedCall}
          leaveCall={leaveCall}
          host={host}
        />
      )}

      {showSettings && (
        <SettingsManagerUI partySocket={partySocket} onClose={leaveSettings} />
      )}
    </>
  );
}
