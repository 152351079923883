import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { NavigationMenuLink } from "@radix-ui/react-navigation-menu";

export interface CallModeMenuProps {
  activeMode: "ai" | "transcript" | "script";
  setActiveMode: (mode: "ai" | "transcript" | "script") => void;
}
export function CallModeMenu({
  activeMode,
  setActiveMode,
}: Readonly<CallModeMenuProps>) {
  return (
    <NavigationMenu
      value={activeMode}
      onValueChange={(value) => {
        if (["ai", "transcript", "script"].includes(value)) {
          setActiveMode(value as "ai" | "transcript" | "script");
        }
      }}
    >
      <NavigationMenuList>
        <NavigationMenuItem value="script">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "script" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("script");
            }}
          >
            Script
          </NavigationMenuLink>
        </NavigationMenuItem>

        <NavigationMenuItem value="ai">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "ai" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("ai");
            }}
          >
            AI
          </NavigationMenuLink>
        </NavigationMenuItem>

        <NavigationMenuItem value="transcript">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "transcript" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("transcript");
            }}
          >
            Transcript
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}
