import { Button } from "@/components/ui/button";
import { useNeutralino } from "./NeutralinoContextProvider";
import { Pin, PinOff } from "lucide-react";

export const ToggleAlwaysOnTop = () => {
  const { isAlwaysOnTop, toggleAlwaysOnTop } = useNeutralino();

  return (
    <Button onClick={toggleAlwaysOnTop} variant="outline" size="icon">
      {isAlwaysOnTop ? (
        <Pin className="h-4 w-4" />
      ) : (
        <PinOff className="h-4 w-4" />
      )}
    </Button>
  );
};
