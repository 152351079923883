import { platform } from "os";
import { CallScriptSchema } from "../client/app/components/Script/types/CallScript";
import { z } from "zod";

export const CallSchema = z.object({
  id: z.string(),
  url: z.string(),
  recallBotId: z.string(),
  callScriptId: z.string().optional(), // TODO make this non-optional once we have migrated?
  status: z.string(),
  createdBy: z.string(),
  createdAt: z.string(),
});

export type Call = z.infer<typeof CallSchema>;

// The old type as used in the front end
export interface PlaybookItem {
  id: string;
  text: string;
  content?: string;
  completed: boolean;
}

export const PlaybookScriptItemSchema = z.object({
  id: z.string(),
  text: z.string(),
  completed: z.boolean(),
  completedAt: z.number().optional(),
  startedAt: z.number().optional(),
  partiallyCompleted: z.boolean().optional(),
});

export type PlaybookScriptItem = z.infer<typeof PlaybookScriptItemSchema>;

export const PlaybookSectionSchema = z.object({
  id: z.string(),
  name: z.string(),
  status: z.enum(["pending", "in_progress", "complete"]),
  startTime: z.number().optional(),
  endTime: z.number().optional(),
  successCriteria: z.string(),
  instructions: z.string(),
  startTranscriptId: z.string().optional(),
  endTranscriptId: z.string().optional(),
  scriptItems: z.array(PlaybookScriptItemSchema),
  currentScriptItemId: z.string().nullable(),
});

export const PlaybookProgressStatusSchema = z.enum([
  "pending",
  "in_progress",
  "complete",
]);

export const PlaybookProgressSchema = z.object({
  sections: z.array(PlaybookSectionSchema),
  currentSectionId: z.string().nullable(),
  currentScriptItemId: z.string().nullable().optional(),
  status: PlaybookProgressStatusSchema,
});

export type PlaybookSection = z.infer<typeof PlaybookSectionSchema>;
export type PlaybookProgress = z.infer<typeof PlaybookProgressSchema>;
export type PlaybookProgressStatus = z.infer<
  typeof PlaybookProgressStatusSchema
>;
export const WebhookTranscriptUpdateSchema = z.object({
  original_transcript_id: z.number().optional(),
  speaker: z.string(),
  speaker_id: z.number(),
  words: z
    .array(
      z.object({
        text: z.string(),
        start_time: z.number(),
        end_time: z.number(),
      })
    )
    .min(1),
  is_final: z.boolean(),
  language: z.string().nullable().optional(),
  source: z.string().nullable().optional(),
});

export type WebhookTranscriptUpdate = z.infer<
  typeof WebhookTranscriptUpdateSchema
>;

export const TranscriptUpdateSchema = WebhookTranscriptUpdateSchema.extend({
  id: z.string(),
  latency: z.number().optional(),
  processed_at: z.number().optional(),
  end_latency: z.number().optional(),
});

export type TranscriptUpdate = z.infer<typeof TranscriptUpdateSchema>;

export const TranscriptWordSchema = z.object({
  text: z.string(),
  start_time: z.number(),
  end_time: z.number(),
});

// Transcript Event
export const TranscriptEventSchema = z.object({
  event: z.literal("bot.transcription"),
  data: z.object({
    bot_id: z.string(),
    recording_id: z.string(),
    transcript: WebhookTranscriptUpdateSchema,
  }),
});

export type TranscriptEvent = z.infer<typeof TranscriptEventSchema>;

// Status codes
export const StatusCodeSchema = z.enum([
  "ready",
  "joining_call",
  "in_waiting_room",
  "participant_in_waiting_room",
  "in_call_not_recording",
  "recording_permission_allowed",
  "recording_permission_denied",
  "in_call_recording",
  "call_ended",
  "recording_done",
  "done",
  "fatal",
  "analysis_done",
  "analysis_failed",
  "media_expired",
  "starting_up",
]);

export type StatusCode = z.infer<typeof StatusCodeSchema>;

// Sub-codes (You'll need to add all possible sub-codes here)
export const SubCodeSchema = z.string().nullable();

export type SubCode = z.infer<typeof SubCodeSchema>;

export const MeetingMetadataSchema = z.record(z.string()).nullable();

export const MeetingUrlSchema = z.union([
  z.string(),
  z.object({
    meeting_id: z.string(),
    platform: z.string(),
  }),
]);

// Status change schema
export const StatusChangeSchema = z.object({
  code: StatusCodeSchema,
  created_at: z.string(),
  sub_code: SubCodeSchema.nullable().optional(),
  message: z.string().nullable().optional(),
  recording_id: z.string().optional(),
});

export type StatusChange = z.infer<typeof StatusChangeSchema>;

// Status change event schema
export const StatusChangeEventSchema = z.object({
  event: z.literal("bot.status_change"),
  data: z.object({
    bot_id: z.string(),
    status: StatusChangeSchema,
  }),
});

export type StatusChangeEvent = z.infer<typeof StatusChangeEventSchema>;

// Bot log event schema
export const BotLogEventSchema = z.object({
  event: z.enum(["bot.log", "bot.output_log"]),
  data: z.object({
    bot_id: z.string().optional(),
    job_id: z.string().optional(),
    log: z.object({
      level: z.string(),
      message: z.string(),
      output_id: z.string().nullable(),
      created_at: z.string(),
    }),
  }),
});

export type BotLogEvent = z.infer<typeof BotLogEventSchema>;

export const ParticipantInWaitingRoomEventSchema = z.object({
  event: z.literal("bot.participant_in_waiting_room"),
  data: z.object({
    bot_id: z.string(),
    participant_id: z.number(),
    created_at: z.string(),
  }),
});

export type ParticipantInWaitingRoomEvent = z.infer<
  typeof ParticipantInWaitingRoomEventSchema
>;

// Participant Join Event
export const ParticipantJoinEventSchema = z.object({
  event: z.literal("bot.participant_join"),
  data: z.object({
    bot_id: z.string(),
    participant_id: z.number(),
    created_at: z.string(),
  }),
});

export type ParticipantJoinEvent = z.infer<typeof ParticipantJoinEventSchema>;

// Participant Leave Event
export const ParticipantLeaveEventSchema = z.object({
  event: z.literal("bot.participant_leave"),
  data: z.object({
    bot_id: z.string(),
    participant_id: z.number(),
    created_at: z.string(),
  }),
});

export type ParticipantLeaveEvent = z.infer<typeof ParticipantLeaveEventSchema>;

// Active Speaker Notify Event
export const ActiveSpeakerNotifyEventSchema = z.object({
  event: z.literal("bot.active_speaker_notify"),
  data: z.object({
    bot_id: z.string(),
    participant_id: z.number(),
    created_at: z.string(),
  }),
});

export type ActiveSpeakerNotifyEvent = z.infer<
  typeof ActiveSpeakerNotifyEventSchema
>;

// Combined WebhookEvent
export const WebhookEventSchema = z.discriminatedUnion("event", [
  BotLogEventSchema,
  TranscriptEventSchema,
  StatusChangeEventSchema,
  ParticipantJoinEventSchema,
  ParticipantLeaveEventSchema,
  ParticipantInWaitingRoomEventSchema,
  ActiveSpeakerNotifyEventSchema,
]);

// Call Event (combination of participant and active speaker events)
export type CallEvent =
  | ParticipantJoinEvent
  | ParticipantLeaveEvent
  | ActiveSpeakerNotifyEvent;
export type WebhookEvent = z.infer<typeof WebhookEventSchema>;
export type TranscriptWord = z.infer<typeof TranscriptWordSchema>;

// Participant event schema
export const ParticipantEventSchema = z.object({
  code: z.string(),
  created_at: z.string(),
});

// Extra data schemas
export const ZoomExtraDataSchema = z.object({
  user_guid: z.string(),
  guest: z.boolean(),
  conf_user_id: z.string(),
});

export const MicrosoftTeamsExtraDataSchema = z.object({
  participant_type: z.string(),
  role: z.string(),
  meeting_role: z.string(),
  user_id: z.string(),
  tenant_id: z.string(),
  client_version: z.string(),
});

export const SlackExtraDataSchema = z.object({
  user_id: z.string(),
  email: z.string(),
});

export const ExtraDataSchema = z.object({
  zoom: ZoomExtraDataSchema.optional(),
  microsoft_teams: MicrosoftTeamsExtraDataSchema.optional(),
  slack: SlackExtraDataSchema.optional(),
});

// Meeting participant schema
export const MeetingParticipantSchema = z.object({
  id: z.number(),
  name: z.string(),
  events: z.array(ParticipantEventSchema),
  is_host: z.boolean(),
  platform: z.string(),
  extra_data: ExtraDataSchema.nullable().optional(),
});

export type MeetingParticipant = z.infer<typeof MeetingParticipantSchema>;

// Calendar meeting schema
export const CalendarMeetingSchema = z.object({
  id: z.string(),
  start_time: z.string(),
  end_time: z.string(),
  calendar_user: z.object({
    id: z.string(),
    external_id: z.string(),
  }),
});

// Recording schema
export const RecordingSchema = z.object({
  id: z.string(),
  started_at: z.string().nullable(),
  completed_at: z.string().nullable(),
});

// Bot result schema
export const RecallBotSchema = z.object({
  id: z.string(),
  video_url: z.string().nullable().optional(),
  media_retention_end: z.string().nullable().optional(),
  status_changes: z.array(StatusChangeSchema).optional(),
  meeting_metadata: MeetingMetadataSchema.optional(),
  meeting_participants: z.array(MeetingParticipantSchema).optional().nullable(),
  meeting_url: MeetingUrlSchema.optional(),
  metadata: z.record(z.unknown()).optional().nullable(),
  join_at: z.string().nullable().optional(),
  calendar_meetings: z.array(CalendarMeetingSchema).optional().nullable(),
  recording: z.string().nullable().optional().nullable(),
  recordings: z.array(RecordingSchema).optional().nullable(),
});

// GET bot response schema
export const GetBotResponseSchema = z.object({
  count: z.number(),
  next: z.string().nullable(),
  previous: z.string().nullable(),
  results: z.array(RecallBotSchema),
});

// CREATE bot response schema
export const CreateBotResponseSchema = RecallBotSchema;

export type GetBotResponse = z.infer<typeof GetBotResponseSchema>;
export type CreateBotResponse = z.infer<typeof CreateBotResponseSchema>;
export type RecallBot = z.infer<typeof RecallBotSchema>;

export const AIQuestionAnswerSchema = z.object({
  id: z.string(),
  question: z.string(),
  answer: z.string(),
  context: z.string(),
  timestamp: z.number(),
  isComplete: z.boolean().optional(),
  questionId: z.string().optional(),
});
export type AIQuestionAnswer = z.infer<typeof AIQuestionAnswerSchema>;

export const PartialAIResponseSchema = z.object({
  id: z.string(),
  question: z.string().nullable(),
  answer: z.string(),
  context: z.string(),
  isComplete: z.boolean(),
  questionId: z.string().optional(),
});
export type PartialAIResponse = z.infer<typeof PartialAIResponseSchema>;

export const AILatencyMetricsSchema = z.object({
  clickTimestamp: z.number(),
  aiRequestTimestamp: z.number(),
  firstTokenTimestamp: z.number(),
  completionTimestamp: z.number(),
  totalDuration: z.number(),
  aiRequestDuration: z.number(),
  generationDuration: z.number(),
  model: z.string(),
  error: z.boolean().optional(),
  questionId: z.string().optional(),
});
export type AILatencyMetrics = z.infer<typeof AILatencyMetricsSchema>;

export const CallQuestionSchema = z.object({
  id: z.string(),
  question: z.string(),
  context: z.string(),
  timestamp: z.number(),
  isAnswered: z.boolean(),
  speakerId: z.number(),
  isFinalized: z.boolean(),
});

export type CallQuestion = z.infer<typeof CallQuestionSchema>;

export const ServerMessageSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("FULL_TRANSCRIPT"),
    payload: z.array(TranscriptUpdateSchema),
  }),
  z.object({
    type: z.literal("AI_HISTORY"),
    payload: z.array(AIQuestionAnswerSchema),
  }),
  z.object({
    type: z.literal("TRANSCRIPT_UPDATE"),
    payload: TranscriptUpdateSchema,
  }),
  z.object({
    type: z.literal("STATUS_UPDATE"),
    payload: z.object({
      status: StatusChangeSchema,
    }),
  }),
  z.object({
    type: z.literal("AI_STREAM"),
    payload: PartialAIResponseSchema,
  }),
  z.object({
    type: z.literal("AI_STREAM_END"),
    payload: PartialAIResponseSchema,
  }),
  z.object({
    type: z.literal("AI_LATENCY_METRICS"),
    payload: AILatencyMetricsSchema,
  }),
  z.object({
    type: z.literal("AI_ERROR"),
    payload: z.object({
      message: z.string(),
    }),
  }),
  z.object({
    type: z.literal("ERROR"),
    payload: z.object({
      message: z.string(),
    }),
  }),
  z.object({
    type: z.literal("BOT_INFO"),
    payload: RecallBotSchema,
  }),
  z.object({
    type: z.literal("CURRENT_PLAYBOOK_SECTION"),
    payload: z.string(),
  }),
  z.object({
    type: z.literal("PLAYBOOK_SECTION_UPDATE"),
    payload: PlaybookSectionSchema,
  }),
  z.object({
    type: z.literal("PLAYBOOK_PROGRESS"),
    payload: PlaybookProgressSchema,
  }),
  z.object({
    type: z.literal("CURRENT_SCRIPT_ITEM"),
    payload: PlaybookScriptItemSchema,
  }),
  z.object({
    type: z.literal("PLAYBOOK_COMPLETE"),
  }),
  z.object({
    type: z.literal("CALL_QUESTIONS_UPDATE"),
    payload: z.array(CallQuestionSchema),
  }),
  z.object({
    type: z.literal("MESSAGE_LATENCY"),
    payload: z.number(),
  }),
  z.object({
    type: z.literal("PROCESS_SCRIPT"),
    payload: z.boolean(),
  }),
]);

export type ServerMessage = z.infer<typeof ServerMessageSchema>;

export const InternalRequestSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("STORE_BOT_INFO"),
    payload: RecallBotSchema,
    isInternalCall: z.literal(true),
    timestamp: z.string().datetime({ precision: 3 }),
  }),
  z.object({
    type: z.literal("STORE_ORG_ID"),
    payload: z.string(),
    isInternalCall: z.literal(true),
    timestamp: z.string().datetime({ precision: 3 }),
  }),
  z.object({
    type: z.literal("SET_CALL_SCRIPT"),
    payload: CallScriptSchema,
    isInternalCall: z.literal(true),
    timestamp: z.string().datetime({ precision: 3 }),
  }),
  z.object({
    type: z.literal("INIT"),
    payload: z.object({
      bot: RecallBotSchema,
      orgId: z.string(),
      callScript: CallScriptSchema,
    }),
    isInternalCall: z.literal(true),
    timestamp: z.string().datetime({ precision: 3 }),
  }),
]);

export type InternalRequest = z.infer<typeof InternalRequestSchema>;

export interface LogMessage {
  message: string;
  timestamp: string;
}
