import { Button } from "@/components/ui/button";
import { useState } from "react";
import { DownloadAppUI } from "./Download/DownloadAppUI";

export const DownloadAppOrContinueOnWeb = ({
  continueOnWeb,
}: {
  continueOnWeb: () => void;
}) => {
  const [displayApps, setDisplayApps] = useState(false);
  return (
    <div className="flex flex-col space-y-4 text-left p-4">
      {displayApps ? (
        <DownloadAppUI />
      ) : (
        <ChooseToDownloadApp choose={() => setDisplayApps(true)} />
      )}
      <div>
        <Button
          variant="secondary"
          onClick={() => {
            continueOnWeb();
          }}
          className="btn btn-secondary"
        >
          Continue on Web
        </Button>
      </div>
    </div>
  );
};

const ChooseToDownloadApp = ({ choose }: { choose: () => void }) => {
  return (
    <>
      <h1 className="text-lg font-medium">Frontier X</h1>
      <p className="text-sm text-gray-400">
        To get the best experience, download our Mac desktop app.
      </p>
      <div className="flex space-x-4 mt-4">
        <Button onClick={() => choose()}>Get the Mac app</Button>
      </div>
    </>
  );
};
