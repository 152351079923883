import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { CallDisplayMode } from "./CallDisplayUI";
import { CallSummary } from "./CallSummary";
import { SummaryOutput } from "party/logic/SummaryGenerator/SummaryGenerator";
import { useState } from "react";
import { LightbulbIcon } from "lucide-react";
import { CallResultsMeasure } from "./CallResultsMeasure";

export type ReportTab = "coaching" | "summary";

interface PerformanceMetric {
  label: string;
  percentage: number;
}

const performanceMetrics: PerformanceMetric[] = [
  { label: "Have the prospect's pain points been discussed?", percentage: 100 },
  { label: "Are the prospect's needs clear?", percentage: 80 },
  {
    label: "Has the prospect current solution been discussed?",
    percentage: 30,
  },
  {
    label: "Did you demonstrate the specific benefits of our solution?",
    percentage: 70,
  },
  {
    label: "Did you make sure the customer's objections were neutralized?",
    percentage: 100,
  },
  { label: "Did you establish who is the decision maker?", percentage: 60 },
  { label: "Did you secure a commitment for the next steps?", percentage: 50 },
];

export const CallResultsReport = ({
  setActiveMode,
  handleRequestSummary,
  summary,
}: {
  setActiveMode: (mode: CallDisplayMode) => void;
  handleRequestSummary: () => void;
  summary: SummaryOutput | null;
}) => {
  const [activeTab, setActiveTab] = useState<ReportTab>("coaching");
  return (
    <Card>
      <CardContent className="w-full p-4 bg-slate-50">
        <div className="flex-grow flex flex-col space-y-4 w-full items">
          <div className="flex flex-row space-x-4 items-center">
            <CallResultsReportTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          {activeTab === "coaching" && <CallResultsCoaching />}
          {activeTab === "summary" && (
            <CallSummary
              handleRequestSummary={handleRequestSummary}
              summary={summary}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export const CallResultsCoaching = () => {
  const getProgressColor = (percentage: number) => {
    if (percentage === 100) return "bg-green-500";
    if (percentage >= 50) return "bg-orange-500";
    return "bg-red-500";
  };
  return (
    <div className="flex flex-col space-y-4">
      <CallResultsMeasure />
      <CallTags />
      <div className="grid gap-4 xl:grid-cols-2">
        <Card>
          <CardHeader>
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-bold">Performance Review</h2>
              <div className="text-sm text-muted-foreground">% ACHIEVED</div>
            </div>
          </CardHeader>
          <CardContent className="space-y-2">
            {performanceMetrics.map((metric, index) => (
              <div key={index} className="space-y-1.5">
                <div className="text-sm">{metric.label}</div>
                <div className="h-2 w-full bg-secondary rounded-full overflow-hidden">
                  <div
                    className={`h-full ${getProgressColor(metric.percentage)}`}
                    style={{ width: `${metric.percentage}%` }}
                  />
                </div>
                <div className="text-right text-sm text-muted-foreground">
                  {metric.percentage}%
                </div>
              </div>
            ))}
          </CardContent>
        </Card>

        <div className="space-y-6">
          <Card>
            <CardHeader>
              <div className="flex items-center gap-2 w-full">
                <h2 className="text-lg font-bold flex-1">
                  Here's what you can improve in your next call
                </h2>
                <div className="relative h-14 w-14 flex-0">
                  <svg className="h-full w-full" viewBox="0 0 100 100">
                    <circle
                      className="text-muted-foreground"
                      strokeWidth="6"
                      stroke="currentColor"
                      fill="transparent"
                      r="44"
                      cx="50"
                      cy="50"
                    />
                    <circle
                      className="text-[#4CAF50]"
                      strokeWidth="6"
                      strokeLinecap="round"
                      stroke="currentColor"
                      fill="transparent"
                      r="44"
                      cx="50"
                      cy="50"
                      strokeDasharray="276.46015351590177"
                      strokeDashoffset="69.11503837897544"
                    />
                  </svg>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-sm font-bold">75%</span>
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardContent className="space-y-4">
              <div>
                <h3 className="mb-2 text-lg font-semibold">
                  Ask more specific questions
                </h3>
                <p className="text-muted-foreground">
                  You did an excellent job understanding the customer pain
                  points, but you should ask more specific questions regarding
                  their current situation. Focus on getting precise answers on
                  the solution they are currently using, and on who is the
                  decision maker.
                </p>
              </div>
              <div>
                <h3 className="mb-2 text-lg font-semibold">
                  Secure the commitment
                </h3>
                <p className="text-muted-foreground">
                  While the call was very good, you need to focus more on what
                  the next steps are, and on getting the prospect to commit to
                  these next steps. Try scheduling a new call before ending the
                  one you're on, and remind the customer that you will follow up
                  with an email in the next few days.
                </p>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-[#FFC107] text-black">
            <CardContent className="p-4">
              <div className="flex items-start gap-2">
                <LightbulbIcon className="h-5 w-5 shrink-0" />
                <p className="text-sm">
                  In your next call, remember to ask who is the decision maker,
                  and make sure you schedule a new call with the prospect. Don't
                  let them forget you're here for them
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
export const CallResultsReportTabs = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: ReportTab;
  setActiveTab: (tab: ReportTab) => void;
}) => {
  return (
    <div className="flex flex-row justify-center w-full space-x-4">
      <Button
        variant={activeTab === "coaching" ? "outline" : "ghost"}
        size="sm"
        onClick={() => setActiveTab("coaching")}
      >
        Coaching
      </Button>
      <Button
        variant={activeTab === "summary" ? "outline" : "ghost"}
        size="sm"
        onClick={() => setActiveTab("summary")}
      >
        Summary
      </Button>
    </div>
  );
};

const CallTags = () => {
  return (
    <div className="mt-4 flex flex-col items-center justify-center space-y-2 text-sm text-muted-foreground">
      <div className="space-x-4">
        <span>Discovery Call</span>
        <span>
          {new Date().toLocaleDateString()}{" "}
          {new Date().toLocaleTimeString(undefined, {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </div>
    </div>
  );
};
