import { Card } from "@/components/ui/card";
import { Sparkles } from "lucide-react";
import type {
  AILatencyMetrics,
  AIQuestionAnswer,
  PartialAIResponse,
} from "party/types";
import ReactMarkdown from "react-markdown";
import { AiHelpLatency } from "./AiHelpLatency";
import { AiHelpCardNextPrevious } from "./AiHelpCardNextPrevious";
import { AiHelpAsk } from "./AiHelpAsk";

export type HandleGetHelpFunction = ({
  question,
  speakerId,
  itemIds,
}: {
  question?: string;
  speakerId?: number;
  itemIds?: string[];
}) => void;
export interface AiHelpCardProps {
  help?: PartialAIResponse | AIQuestionAnswer | null;
  latencyMetrics?: AILatencyMetrics;
  handlePrevious: () => void;
  handleNext: () => void;
  currentIndex: number;
  historyLength: number;
  handleGetHelp: HandleGetHelpFunction;
}

export function AiHelpCard({
  help,
  latencyMetrics,
  handlePrevious,
  handleNext,
  currentIndex,
  historyLength,
  handleGetHelp,
}: Readonly<AiHelpCardProps>) {
  return (
    <Card className="p-4 w-full flex flex-col space-y-4">
      <AiHelpAsk onAskQuestion={handleGetHelp} />
      {help && (
        <>
          <div className="flex justify-between items-center mb-2">
            <h2 className="font-bold text-md">
              <Sparkles size={16} className="mr-2 inline-block" />{" "}
              {help.question ?? "…"}
            </h2>
            <AiHelpCardNextPrevious
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              currentIndex={currentIndex}
              historyLength={historyLength}
            />
          </div>
          <ReactMarkdown className="prose prose-sm max-w-none dark:prose-invert">
            {help.answer ?? "…"}
          </ReactMarkdown>
          {latencyMetrics && <AiHelpLatency latencyMetrics={latencyMetrics} />}
        </>
      )}
    </Card>
  );
}
